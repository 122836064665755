import { Elm } from "./src/Main.elm";
// import * as ElmDebugger from "elm-debug-transformer";

// ElmDebugger.register();

const backendUrl = process.env.BACKEND_URL ?? "http://localhost:3000";
const signedIn = process.env.SIGNED_IN === "True";

let app = Elm.Main.init({
  node: document.querySelector("main"),
  flags: {
    apiKey: "$2b$10$QguCM0Fds14VuG7b5kMsM.Hgw7zrOtjgEPZwGxC.Vhn.lh8nm/5s.",
    backendUrl: backendUrl,
    dimensions: {
      height: window.screen.availHeight,
      width: window.screen.availWidth
    },
    signedIn: signedIn,
    env: process.env.ENV ?? "PROD"
  }
});

app.ports.openWindow.subscribe(url => {
  window.open(`${backendUrl}/${url}`);
});

app.ports.refreshPage.subscribe(() => {
  window.location.reload();
});

var refresh_rate = 600;
var last_user_action = 0;
var has_focus = false;
var lost_focus_count = 0;
var focus_margin = 10;

// Reset the Timer on users last action
function reset() {
  last_user_action = 0;
}

function windowHasFocus() {
  has_focus = true;
}

function windowLostFocus() {
  has_focus = false;
  lost_focus_count++;
}

// Count Down that executes every second
setInterval(function() {
  last_user_action++;
  refreshCheck();
}, 1000);

// The code that checks if the window needs to reload
function refreshCheck() {
  if (
    last_user_action >= refresh_rate &&
    !has_focus &&
    document.readyState == "complete"
  ) {
    app.ports.refreshAppData.send(null);
    reset();
  }
}

window.addEventListener("focus", windowHasFocus, false);
window.addEventListener("blur", windowLostFocus, false);
window.addEventListener("click", reset, false);
window.addEventListener("mousemove", reset, false);
window.addEventListener("keypress", reset, false);
window.addEventListener("scroll", reset, false);
document.addEventListener("touchMove", reset, false);
document.addEventListener("touchEnd", reset, false);
