module Main exposing (main)

import Api exposing (fetchAppData)
import Attr as Attr exposing (..)
import Browser
import Color exposing (..)
import Date exposing (Date, Interval(..), Unit(..))
import Dict
import Element exposing (..)
import Element.Background as Background
import Element.Border as Border
import Element.Events exposing (..)
import Element.Font as Font
import Element.Input as Input
import Helpers exposing (..)
import Http as Http
import Json.Decode.Pipeline exposing (..)
import List.Extra as List
import Pages.Customers as Customers
import Pages.OnlineOrders as OnlineOrders
import Pages.Orders as Orders
import Pages.Production as Production
import Pages.Products as Products
import Pages.WeeklyViews as WeeklyViews
import Ports exposing (..)
import Process
import Task
import Types exposing (..)
import Utils exposing (..)


type Page
    = OrdersPage Orders.Model
    | ProductsPage Products.Model
    | CustomersPage Customers.Model
    | WeeklyViewPage WeeklyViews.Model
    | ProductionPage Production.Model
    | OnlineOrdersPage OnlineOrders.Model


type GoTo
    = GoToProducts
    | GoToOrders
    | GoToCustomers
    | GoToWeeklyView
    | GoToProduction
    | GoToOnlineOrders


type Msg
    = RefreshAppData ()
    | ReloadPage
    | AppDataUpdated (Result Http.Error AppDataResponse)
    | AppDataFetched (Result Http.Error AppDataResponse)
    | LatLngFetched Customer AppData (Result Http.Error LocationResponse)
    | MissingLatLngFetched Customer (Result Http.Error LocationResponse)
    | RouteFetched (Result Http.Error RouteResponse)
    | WholesaleRouteFetched Int (Result Http.Error RouteResponse)
    | DocumentGenerated (Result Http.Error GenerateDocumentResponse)
    | Go GoTo
    | TodaysDateReceived Date
    | Orders Orders.Msg
    | Products Products.Msg
    | Customers Customers.Msg
    | WeeklyView WeeklyViews.Msg
    | Production Production.Msg
    | OnlineOrders OnlineOrders.Msg
    | OldOrdersFetched String (Result Http.Error OldWeeklyOrders)
    | ToggleMenu
    | UpdateOrderHistory AppData (Result Http.Error OldWeeklyOrders)
    | GoToNextWeek
    | OrderHistoryUpdated (Result Http.Error OldWeeklyOrderResponse)
    | UpdatePasscode String
    | FetchMissingCustomerLocations Customer
    | FetchNextWholesaleRoute
    | Logout ()


type alias Model =
    { appState : AppState
    , appData : AppData
    , currPage : Page
    , selectedNavigation : Navigation
    , signedIn : Bool
    , password : String
    , loaded : Bool
    , goToNextWeek : Bool
    }


type Navigation
    = OnOrdersPage
    | OnProductsPage
    | OnCustomersPage
    | OnWeeklyPage
    | OnProductionPage
    | OnOnlineOrdersPage


init : Flags -> ( Model, Cmd Msg )
init flags =
    let
        appState =
            defaultAppState flags
    in
    ( { appState = appState
      , appData = defaultAppData

      -- , currPage = OrdersPage <| Orders.init appState defaultAppData
      , currPage = ProductionPage <| Production.init appState defaultAppData

      -- , selectedNavigation = OnOrdersPage
      , selectedNavigation = OnProductionPage
      , signedIn = flags.signedIn
      , password = ""
      , loaded = True
      , goToNextWeek = False
      }
    , Date.today |> Task.perform TodaysDateReceived
    )



-- Subscriptions


subscriptions : Model -> Sub Msg
subscriptions _ =
    Ports.refreshAppData Logout


update : Msg -> Model -> ( Model, Cmd Msg )
update msg ({ appState, appData } as model) =
    case ( msg, model.currPage ) of
        ( Logout (), _ ) ->
            ( { model
                | signedIn = False
                , password = ""
              }
            , Cmd.none
            )

        ( ReloadPage, _ ) ->
            ( model
            , Ports.refreshPage ()
            )

        ( GoToNextWeek, _ ) ->
            ( { model | loaded = False }
            , Api.fetchOldOrders
                appState.apiKey
                (UpdateOrderHistory appData)
            )

        ( RefreshAppData (), _ ) ->
            ( { model | loaded = False }
            , fetchAppData appState.apiKey AppDataFetched
            )

        ( UpdatePasscode str, _ ) ->
            let
                ( signedIn, password, toDo ) =
                    if str == "se163eh" then
                        ( True, "", fireAction RefreshAppData () )

                    else
                        ( False, str, Cmd.none )
            in
            ( { model
                | signedIn = signedIn
                , password = password
              }
            , toDo
            )

        ( ToggleMenu, _ ) ->
            let
                updatedAppState =
                    { appState | menuIsOpen = not appState.menuIsOpen }
            in
            ( { model | appState = updatedAppState }, Cmd.none )

        ( MissingLatLngFetched c (Ok res), _ ) ->
            let
                ad =
                    model.appData

                updatedAd =
                    res
                        |> List.head
                        |> Maybe.map
                            (\{ lat, lon } ->
                                let
                                    updatedCustomer =
                                        { c | latLng = { lat = lat, lng = lon } }
                                in
                                { ad
                                    | customers =
                                        toCustomerLookup ad.customers
                                            |> (\cLookup ->
                                                    Dict.insert c.id
                                                        updatedCustomer
                                                        cLookup
                                               )
                                            |> dictToList
                                }
                            )
                        |> Maybe.withDefault ad

                customerMissingLatLngCmd =
                    List.filter
                        (\{ latLng } ->
                            (String.isEmpty latLng.lat
                                || String.isEmpty latLng.lng
                            )
                                && (not <| String.isEmpty c.address)
                                && (not <| String.isEmpty c.postCode)
                                && (c.deliveryMethod == Van)
                        )
                        updatedAd.customers
                        |> List.head
                        |> Maybe.map
                            (\customer ->
                                fillMissingCustomerLocation
                                    customer
                                    updatedAd
                            )
                        |> Maybe.withDefault Cmd.none
            in
            ( { model | appData = updatedAd }
            , customerMissingLatLngCmd
            )

        ( MissingLatLngFetched _ (Err e), _ ) ->
            ( { model
                | appState =
                    { appState
                        | lastError = httpErrorString e
                    }
              }
            , Cmd.none
            )

        ( FetchMissingCustomerLocations c, _ ) ->
            ( model
            , Api.fetchLatLng
                c
                (MissingLatLngFetched c)
            )

        ( LatLngFetched c ad (Ok res), _ ) ->
            let
                updatedAd =
                    res
                        |> List.head
                        |> Maybe.map
                            (\{ lat, lon } ->
                                let
                                    updatedCustomer =
                                        { c | latLng = { lat = lat, lng = lon } }
                                in
                                { ad
                                    | customers =
                                        toCustomerLookup ad.customers
                                            |> (\cLookup ->
                                                    Dict.insert c.id
                                                        updatedCustomer
                                                        cLookup
                                               )
                                            |> dictToList
                                }
                            )
                        |> Maybe.withDefault ad
            in
            ( model
            , Api.updateAppData
                appState.apiKey
                updatedAd
                AppDataUpdated
            )

        ( LatLngFetched _ _ (Err e), _ ) ->
            ( { model
                | appState =
                    { appState
                        | lastError = httpErrorString e
                    }
              }
            , Cmd.none
            )

        ( TodaysDateReceived date, _ ) ->
            let
                todaysWeekday =
                    { day = Date.weekday date
                    , dateString = Date.toIsoString date
                    , date = date
                    , viewWeek = OnCurrentWeek
                    }

                monday =
                    date
                        |> Date.floor Monday

                sunday =
                    monday |> Date.add Days 7

                nextSunday =
                    sunday |> Date.add Days 7

                ( current, next ) =
                    ( Date.range Day 1 monday sunday |> toWeekDates
                    , Date.range Day 1 sunday nextSunday |> toWeekDates
                    )

                weeklyDates =
                    { current = current
                    , next = next
                    }

                bootStrap =
                    case ( current, next ) of
                        ( Just _, Just _ ) ->
                            ( { model
                                | appState =
                                    { appState
                                        | currentWeekStartDate =
                                            monday
                                                |> Date.toIsoString
                                        , weeklyDateRanges = weeklyDates
                                        , selectedWeeklyOrders =
                                            stringToWeeklyOrders "Current Week" weeklyDates
                                        , todaysWeekday = todaysWeekday
                                    }
                              }
                            , fetchAppData appState.apiKey AppDataFetched
                            )

                        _ ->
                            ( { model
                                | appState =
                                    { appState
                                        | lastError = "Failed to parse dates"
                                    }
                              }
                            , Cmd.none
                            )
            in
            bootStrap

        ( Go goto, _ ) ->
            let
                ( goToPage, onPage ) =
                    case goto of
                        GoToProducts ->
                            ( ProductsPage <| Products.init model.appState model.appData
                            , OnProductsPage
                            )

                        GoToOrders ->
                            ( OrdersPage <| Orders.init model.appState model.appData
                            , OnOrdersPage
                            )

                        GoToCustomers ->
                            ( CustomersPage <| Customers.init model.appState model.appData
                            , OnCustomersPage
                            )

                        GoToWeeklyView ->
                            ( WeeklyViewPage <|
                                WeeklyViews.init
                                    model.appState
                                    model.appData
                            , OnWeeklyPage
                            )

                        GoToProduction ->
                            ( ProductionPage <|
                                Production.init
                                    model.appState
                                    model.appData
                            , OnProductionPage
                            )

                        GoToOnlineOrders ->
                            ( OnlineOrdersPage <|
                                OnlineOrders.init
                                    model.appState
                                    model.appData
                            , OnOnlineOrdersPage
                            )

                updatedAppState =
                    { appState | menuIsOpen = False }
            in
            ( { model
                | currPage = goToPage
                , selectedNavigation = onPage
                , appState = updatedAppState
              }
            , Cmd.none
            )

        ( OldOrdersFetched orderStart (Ok data), _ ) ->
            let
                foundOldOrder =
                    List.find
                        (\{ startDate } -> startDate == orderStart)
                        data

                withOldOrder =
                    { appData
                        | oldOrder = foundOldOrder
                    }

                updatedAppState =
                    { appState
                        | selectedWeeklyOrders = OldWeek orderStart
                        , oldWeeklyOrders = data
                    }

                updatedAppData =
                    updateAppData
                        updatedAppState
                        withOldOrder
            in
            ( { model
                | appData = updatedAppData
                , appState = updatedAppState
                , currPage =
                    refreshPage
                        updatedAppData
                        updatedAppState
                        model.currPage
              }
            , Cmd.none
            )

        ( OldOrdersFetched _ (Err err), _ ) ->
            ( { model
                | appState =
                    { appState
                        | lastError = httpErrorString err
                    }
              }
            , Cmd.none
            )

        ( UpdateOrderHistory data (Ok oldOrders), _ ) ->
            let
                ( updatedAppData, updatedOldOrders ) =
                    updateAppDataToNextWeek
                        oldOrders
                        appState
                        data

                apiUpdate =
                    Api.updateAppData
                        appState.apiKey
                        updatedAppData
                        AppDataUpdated

                _ =
                    Api.updateOrderHistory
                        appState.apiKey
                        updatedAppData.customers
                        updatedAppData.products
                        updatedOldOrders
                        OrderHistoryUpdated
            in
            ( model, Cmd.batch [ apiUpdate ] )

        ( OrderHistoryUpdated _, _ ) ->
            ( model, Cmd.none )

        ( UpdateOrderHistory _ (Err e), _ ) ->
            ( { model
                | appState =
                    { appState | lastError = httpErrorString e }
              }
            , Cmd.none
            )

        ( AppDataFetched (Ok data), _ ) ->
            --     let
            --         historyCmd =
            --             Api.fetchOldOrders appState.apiKey
            --                 (UpdateOrderHistory data)
            --     in
            --     ( model, historyCmd )
            -- else
            let
                updatedAppData =
                    updateAppData
                        appState
                        data.data

                selectedOrders =
                    updateSelectedOrders
                        updatedAppData
                        appState

                updatedAppState =
                    { appState | appDataLoaded = True }
            in
            ( { model
                | appData = selectedOrders
                , appState = updatedAppState
                , loaded = True
                , currPage =
                    refreshPage
                        updatedAppData
                        updatedAppState
                        model.currPage
                , goToNextWeek =
                    data.data.metadata.currentWeekStartDate /= appState.currentWeekStartDate
              }
            , Cmd.none
              -- , List.filter
              --     (\({ latLng } as c) ->
              --         (String.isEmpty latLng.lat
              --             || String.isEmpty latLng.lng
              --         )
              --             && (not <| String.isEmpty c.address)
              --             && (not <| String.isEmpty c.postCode)
              --             && (c.deliveryMethod == Van)
              --     )
              --     updatedAppData.customers
              --     |> List.head
              --     |> Maybe.map
              --         (\customer ->
              --             fillMissingCustomerLocation
              --                 customer
              --                 updatedAppData
              --         )
              --     |> Maybe.withDefault Cmd.none
            )

        ( AppDataFetched (Err err), _ ) ->
            ( { model
                | appState = { appState | lastError = httpErrorString err }
                , loaded = True
              }
            , Cmd.none
            )

        ( AppDataUpdated (Ok res), _ ) ->
            let
                data =
                    res.data

                updatedAppData =
                    updateAppData
                        model.appState
                        data

                updatedAppState =
                    { appState | appDataLoaded = True }
            in
            ( { model
                | appData = updatedAppData
                , appState = updatedAppState
                , currPage =
                    refreshPage
                        updatedAppData
                        updatedAppState
                        model.currPage
              }
            , Cmd.none
            )

        ( AppDataUpdated (Err err), _ ) ->
            ( { model
                | appState = { appState | lastError = httpErrorString err }
              }
            , Cmd.none
            )

        ( WeeklyView weekMsg, WeeklyViewPage pageModel ) ->
            let
                ( weeklyViewModel, weeklyViewCmd, pageMsg ) =
                    WeeklyViews.update weekMsg appState appData pageModel

                ( newModel, wCmd ) =
                    case pageMsg of
                        WeeklyViews.DoNothing ->
                            ( { model | currPage = WeeklyViewPage weeklyViewModel }
                            , Cmd.none
                            )

                        WeeklyViews.SelectWeeklyOrdersView wo ->
                            let
                                updatedAppState =
                                    { appState
                                        | selectedWeeklyOrders = wo
                                    }

                                updatedOrders =
                                    updateSelectedOrders appData updatedAppState
                            in
                            ( { model
                                | currPage = WeeklyViewPage weeklyViewModel
                                , appState = updatedAppState
                                , appData = updatedOrders
                              }
                            , Cmd.none
                            )

                        WeeklyViews.SelectOldWeeklyOrder order ->
                            ( { model
                                | appState =
                                    { appState
                                        | selectedOldWeeklyOrder =
                                            Just order
                                    }
                              }
                            , Api.fetchOldOrders appState.apiKey
                                (OldOrdersFetched order)
                            )
            in
            ( newModel, Cmd.batch [ wCmd, Cmd.map WeeklyView weeklyViewCmd ] )

        ( OnlineOrders onlineOrdersMsg, OnlineOrdersPage pageModel ) ->
            let
                ( onlineOrdersModel, onlineOrdersCmd, pageMsg ) =
                    OnlineOrders.update onlineOrdersMsg model.appState model.appData pageModel

                updatedPage =
                    { model | currPage = OnlineOrdersPage onlineOrdersModel }

                ( newModel, pCmd ) =
                    case pageMsg of
                        OnlineOrders.DoNothing ->
                            ( updatedPage
                            , Cmd.none
                            )

                        OnlineOrders.UpdateAppData mbCustomer ad ->
                            let
                                updateAppDataCmd =
                                    Api.updateAppData
                                        appState.apiKey
                                        ad
                                        AppDataUpdated

                                apiCmd =
                                    updateLocationData
                                        mbCustomer
                                        ad.customers
                                        ad
                                        appState
                            in
                            ( updatedPage
                            , apiCmd
                            )

                        OnlineOrders.PrintOrders orders ->
                            ( updatedPage
                            , Api.generateOnlineOrders
                                appState
                                orders
                                DocumentGenerated
                            )

                        OnlineOrders.PrintRoute routes ->
                            ( updatedPage
                            , Api.generateRouteSheet
                                appState
                                routes
                                DocumentGenerated
                            )

                        OnlineOrders.FetchRoute orders customers ->
                            ( updatedPage
                            , Api.fetchRoute
                                orders
                                customers
                                RouteFetched
                            )
            in
            ( newModel, Cmd.batch [ pCmd, Cmd.map OnlineOrders onlineOrdersCmd ] )

        ( RouteFetched (Ok res), OnlineOrdersPage pageModel ) ->
            ( { model
                | currPage =
                    OnlineOrdersPage
                        { pageModel
                            | route = Just res
                            , loaded = True
                        }
              }
            , Cmd.none
            )

        ( RouteFetched (Err e), OnlineOrdersPage pageModel ) ->
            ( { model
                | currPage =
                    OnlineOrdersPage
                        { pageModel
                            | route = Nothing
                            , loaded = True
                        }
                , appState =
                    { appState
                        | lastError = httpErrorString e
                    }
              }
            , Cmd.none
            )

        ( FetchNextWholesaleRoute, ProductionPage pageModel ) ->
            let
                ( productionModel, productionCmd, pageMsg ) =
                    Production.update Production.FetchAllRoutes model.appState model.appData pageModel

                updatedPage =
                    { model | currPage = ProductionPage productionModel }

                nextRoute =
                    case pageMsg of
                        Production.FetchWholesaleRoute runId route ->
                            Api.fetchRoutes
                                route
                                (WholesaleRouteFetched runId)

                        _ ->
                            Cmd.none
            in
            ( updatedPage
            , Cmd.batch [ nextRoute, Cmd.map Production productionCmd ]
            )

        ( WholesaleRouteFetched runId (Ok res), ProductionPage pageModel ) ->
            ( { model
                | currPage =
                    ProductionPage
                        { pageModel
                            | routeRunResults =
                                Dict.insert
                                    runId
                                    res
                                    pageModel.routeRunResults
                        }
              }
            , fireAction (\_ -> FetchNextWholesaleRoute) ()
            )

        ( WholesaleRouteFetched _ (Err e), _ ) ->
            ( { model
                | appState =
                    { appState
                        | lastError = httpErrorString e
                    }
              }
            , Cmd.none
            )

        ( Production productionMsg, ProductionPage pageModel ) ->
            let
                ( productionModel, productionCmd, pageMsg ) =
                    Production.update productionMsg model.appState model.appData pageModel

                updatedPage =
                    { model | currPage = ProductionPage productionModel }

                ( newModel, pCmd ) =
                    case pageMsg of
                        Production.DoNothing ->
                            ( updatedPage
                            , Cmd.none
                            )

                        Production.OpenDeliveryNotes notes ->
                            ( updatedPage
                            , Api.generateDeliveryNotes
                                appState
                                notes
                                DocumentGenerated
                            )

                        Production.OpenShapingNotes notes ->
                            ( updatedPage
                            , Api.generateShapingNotes
                                appState
                                notes
                                DocumentGenerated
                            )

                        Production.OpenMixingNotes notes ->
                            ( updatedPage
                            , Api.generateMixingNotes
                                appState
                                notes
                                DocumentGenerated
                            )

                        Production.UpdateAppData ad ->
                            ( updatedPage
                            , Api.updateAppData
                                appState.apiKey
                                ad
                                AppDataUpdated
                            )

                        Production.GenerateInvoices invs ->
                            ( updatedPage
                            , Api.generateInvoices
                                appState
                                invs
                                DocumentGenerated
                            )

                        Production.FetchWholesaleRoute runId route ->
                            ( updatedPage
                            , Api.fetchRoutes
                                route
                                (WholesaleRouteFetched runId)
                            )

                        Production.PrintAllRuns runs ->
                            ( updatedPage
                            , Api.generateRunSheets
                                appState
                                runs
                                DocumentGenerated
                            )
            in
            ( newModel, Cmd.batch [ pCmd, Cmd.map Production productionCmd ] )

        ( DocumentGenerated (Ok res), _ ) ->
            if res.success == True then
                ( model, openWindow res.fileName )

            else
                ( { model
                    | appState =
                        { appState | lastError = "Unable to view document" }
                  }
                , Cmd.none
                )

        ( DocumentGenerated (Err e), _ ) ->
            ( { model
                | appState =
                    { appState | lastError = httpErrorString e }
              }
            , Cmd.none
            )

        ( Orders orderMsg, OrdersPage pageModel ) ->
            let
                ( orderModel, orderCmd, pageMsg ) =
                    Orders.update orderMsg model.appState model.appData pageModel

                ( newModel, oCmd ) =
                    case pageMsg of
                        Orders.DoNothing ->
                            ( { model | currPage = OrdersPage orderModel }
                            , Cmd.none
                            )

                        Orders.UpdateAppData ad ->
                            let
                                apiUpdate =
                                    Api.updateAppData
                                        appState.apiKey
                                        ad
                                        AppDataUpdated
                            in
                            ( { model | currPage = OrdersPage orderModel }
                            , apiUpdate
                            )
            in
            ( newModel, Cmd.batch [ oCmd, Cmd.map Orders orderCmd ] )

        ( Products productMsg, ProductsPage pageModel ) ->
            let
                ( productModel, productCmd, pageMsg ) =
                    Products.update productMsg model.appData pageModel

                ( newModel, cmd ) =
                    case pageMsg of
                        Products.DoNothing ->
                            ( { model | currPage = ProductsPage productModel }
                            , Cmd.none
                            )

                        Products.UpdateProductsOnly products ->
                            let
                                updateModel =
                                    { model
                                        | appData =
                                            { appData
                                                | products = products
                                            }
                                    }

                                apiUpdate =
                                    Api.updateAppData
                                        appState.apiKey
                                        updateModel.appData
                                        AppDataUpdated
                            in
                            ( updateModel
                            , apiUpdate
                            )

                        Products.UpdateAppData ad ->
                            ( { model | currPage = ProductsPage productModel }
                            , Api.updateAppData
                                appState.apiKey
                                ad
                                AppDataUpdated
                            )
            in
            ( newModel, Cmd.batch [ Cmd.map Products productCmd, cmd ] )

        ( Customers customerMsg, CustomersPage pageModel ) ->
            let
                ( customerModel, customerCmd, pageMsg ) =
                    Customers.update customerMsg pageModel

                ( newModel, cmd ) =
                    case pageMsg of
                        Customers.DoNothing ->
                            ( { model | currPage = CustomersPage customerModel }
                            , Cmd.none
                            )

                        Customers.UpdateCustomers mbCustomer customers ->
                            let
                                apiCmd =
                                    updateLocationData
                                        mbCustomer
                                        (dictToList customers)
                                        appData
                                        appState
                            in
                            ( { model
                                | currPage =
                                    CustomersPage
                                        { customerModel
                                            | loaded = False
                                        }
                              }
                            , apiCmd
                            )

                        Customers.PushError err ->
                            ( { model | appState = { appState | lastError = err } }
                            , Cmd.none
                            )
            in
            ( newModel, Cmd.batch [ Cmd.map Customers customerCmd, cmd ] )

        ( _, _ ) ->
            ( model, Cmd.none )



-- View


navBar : Model -> Element Msg
navBar { selectedNavigation, appState, goToNextWeek } =
    let
        menuLink =
            Input.button
                (Attr.greyButton ++ [ padding 5 ])
                { onPress = Just <| Go GoToOrders
                , label = el [ centerX ] <| text "Orders"
                }

        menuButton action label pageSelected =
            let
                buttonActive =
                    if pageSelected == selectedNavigation then
                        Attr.greenButtonHighlights

                    else
                        Attr.greyButton
            in
            Input.button
                (buttonActive
                    ++ [ padding 5 ]
                )
                { onPress = action
                , label = el [ centerX ] <| text label
                }

        ( weekStateTitle, weekStateDate ) =
            case appState.selectedWeeklyOrders of
                OldWeek d ->
                    ( "Old Week", d )

                CurrentWeeklyOrders swo _ ->
                    ( standardWeeklyOrdersToString swo
                    , case swo of
                        CurrentWeek ->
                            appState.currentWeekStartDate

                        _ ->
                            ""
                    )
    in
    row
        [ width fill
        , paddingXY 60 10
        , Border.widthEach { bottom = 1, top = 0, left = 0, right = 0 }
        , Border.color blue
        ]
        [ el [ alignLeft ] <| text "Snapery Bakery Admin"
        , el [ alignRight ] <|
            row
                [ width fill
                , spacingXY 20 0
                ]
                [ menuButton (Just <| Go GoToOrders)
                    "Orders"
                    OnOrdersPage
                , menuButton (Just <| Go GoToProduction) "Production" OnProductionPage
                , menuButton (Just <| Go GoToOnlineOrders) "Online Orders" OnOnlineOrdersPage
                , menuButton (Just <| Go GoToCustomers)
                    "Customers"
                    OnCustomersPage
                , menuButton (Just <| Go GoToProducts)
                    "Products"
                    OnProductsPage
                , buttonInput
                    "Refresh"
                    greenButton
                    ReloadPage
                , renderIfTrue
                    goToNextWeek
                  <|
                    buttonInput
                        "Advance Week"
                        redButton
                        GoToNextWeek
                , column [ spacingXY 0 10 ]
                    [ el [ centerX ] <| text <| weekStateTitle
                    , el [ centerX ] <|
                        (\sd -> Element.text <| formatDateString sd) <|
                            weekStateDate
                    ]
                ]
        ]


view : Model -> Browser.Document Msg
view model =
    let
        content =
            case model.currPage of
                OrdersPage pageModel ->
                    Orders.page pageModel
                        |> Element.map Orders

                ProductsPage pageModel ->
                    Products.page pageModel
                        |> Element.map Products

                CustomersPage pageModel ->
                    Customers.page pageModel
                        |> Element.map Customers

                WeeklyViewPage pageModel ->
                    WeeklyViews.page pageModel
                        |> Element.map WeeklyView

                ProductionPage pageModel ->
                    Production.page pageModel
                        |> Element.map Production

                OnlineOrdersPage pageModel ->
                    OnlineOrders.page pageModel
                        |> Element.map OnlineOrders

        options =
            { options =
                [ focusStyle
                    { borderColor = Just Color.green
                    , backgroundColor = Nothing
                    , shadow =
                        Just <|
                            { color = Color.green
                            , offset = ( 1, 1 )
                            , blur = 1
                            , size = 1
                            }
                    }
                ]
            }

        body =
            if model.signedIn then
                [ layoutWith options
                    [ inFront <| menuPanel model ]
                  <|
                    Attr.loadingWrapper
                        model.loaded
                    <|
                        column [ width fill, spacingXY 0 20, Font.size 16 ]
                            [ navBar model
                            , content
                            ]
                ]

            else
                [ el [ centerY, centerX, width (px 400) ]
                    (Attr.passwordInput
                        { label = "Password"
                        , placeholder = "Password"
                        , value = model.password
                        , width = Attr.halfWidth
                        }
                        UpdatePasscode
                    )
                    |> Element.layout
                        [ height fill ]
                ]
    in
    { title = "Snapery Admin"
    , body = body
    }


menuPanel : Model -> Element Msg
menuPanel { appState, selectedNavigation } =
    let
        menuLink =
            Input.button
                (Attr.greyButton ++ [ padding 5 ])
                { onPress = Just <| Go GoToOrders
                , label = el [ centerX ] <| text "Orders"
                }

        menuButton action label pageSelected =
            let
                buttonActive =
                    if pageSelected == selectedNavigation then
                        Attr.greenButtonHighlights

                    else
                        Attr.greyButton
            in
            Input.button
                (buttonActive
                    ++ [ padding 5 ]
                )
                { onPress = action
                , label = el [ centerX ] <| text label
                }

        items =
            [ menuButton (Just <| Go GoToCustomers)
                "Customers"
                OnCustomersPage
            , menuButton (Just <| Go GoToProducts)
                "Products"
                OnProductsPage
            , menuButton (Just <| Go GoToWeeklyView)
                "Weekly View"
                OnWeeklyPage
            ]

        panel =
            column
                [ Background.color Color.white
                , Border.widthEach { left = 1, right = 0, top = 0, bottom = 0 }
                , Border.color Color.grey
                , Border.shadow
                    { offset = ( 0, 0 )
                    , size = 1
                    , blur = 10
                    , color = Color.lightCharcoal
                    }
                , Font.bold
                , Font.color Color.darkCharcoal
                , Font.family [ Font.sansSerif ]
                , width <| fillPortion 1
                , height fill
                , paddingXY 20 20
                , spacingXY 0 20
                ]
                items

        overlay =
            el [ width <| fillPortion 4, height fill, onClick ToggleMenu ] none
    in
    if appState.menuIsOpen then
        row [ width fill, height fill ] [ overlay, panel ]

    else
        none


refreshPage : AppData -> AppState -> Page -> Page
refreshPage appData appState page =
    case page of
        OrdersPage _ ->
            OrdersPage <|
                Orders.init
                    appState
                    appData

        CustomersPage _ ->
            CustomersPage <|
                Customers.init
                    appState
                    appData

        ProductsPage _ ->
            ProductsPage <|
                Products.init
                    appState
                    appData

        WeeklyViewPage _ ->
            WeeklyViewPage <|
                WeeklyViews.init
                    appState
                    appData

        ProductionPage _ ->
            ProductionPage <|
                Production.init
                    appState
                    appData

        OnlineOrdersPage _ ->
            OnlineOrdersPage <|
                OnlineOrders.init
                    appState
                    appData


fillMissingCustomerLocation :
    Customer
    -> AppData
    -> Cmd Msg
fillMissingCustomerLocation c appData =
    if
        (String.isEmpty c.latLng.lat
            || String.isEmpty c.latLng.lng
        )
            && (not <| String.isEmpty c.address)
            && (not <| String.isEmpty c.postCode)
            && (c.deliveryMethod == Van)
    then
        Process.sleep 1500
            |> Task.perform (\_ -> FetchMissingCustomerLocations c)

    else
        Cmd.none


updateLocationData :
    Maybe Customer
    -> Customers
    -> AppData
    -> AppState
    -> Cmd Msg
updateLocationData mbCustomer customers appData appState =
    let
        updatedAppData =
            { appData | customers = customers }

        updateAppDataCmd =
            Api.updateAppData
                appState.apiKey
                updatedAppData
                AppDataUpdated
    in
    mbCustomer
        |> Maybe.map
            (\c ->
                if
                    (String.isEmpty c.latLng.lat
                        || String.isEmpty c.latLng.lng
                    )
                        && (not <| String.isEmpty c.address)
                        && (not <| String.isEmpty c.postCode)
                then
                    Api.fetchLatLng
                        c
                        (LatLngFetched c updatedAppData)

                else
                    updateAppDataCmd
            )
        |> Maybe.withDefault updateAppDataCmd


updateSelectedOrders : AppData -> AppState -> AppData
updateSelectedOrders ({ orders } as ad) appState =
    case appState.selectedWeeklyOrders of
        CurrentWeeklyOrders wo _ ->
            let
                newSO =
                    case wo of
                        CurrentWeek ->
                            orders.currentWeek

                        NextWeek ->
                            orders.nextWeek

                        StandingOrders ->
                            orders.standingOrders

                        InvalidWeek ->
                            orders.currentWeek
            in
            { ad
                | selectedOrders = newSO
            }

        OldWeek sd ->
            List.find
                (\{ startDate } -> startDate == sd)
                appState.oldWeeklyOrders
                |> Maybe.map
                    (\oldOrder ->
                        { ad
                            | selectedOrders =
                                flatOrderToStandingOrder
                                    oldOrder.flatOrders
                        }
                    )
                |> Maybe.withDefault ad


main : Program Flags Model Msg
main =
    Browser.document
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        }
